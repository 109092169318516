<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="!loading">
        <b-card>
          <h4 class="mb-2">
            Ankieta poszkoleniowa
          </h4>
          <b-row>
            <b-col
              v-if="!isFordPoland"
              cols="12"
              md="4"
            >
              <b-card>
                <vue-qrcode
                  :value="qrCodeValue"
                  class="qrcode"
                  :options="{ margin: 0, width: 256 }"
                />
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <template v-if="!isFordPoland">
                Pobierz swój QR kod szkolenia, a następnie umieść go na ostatniej
                stronie prezentacji szkoleniowej. <br><br>Wypełnienie ankiet
                przez uczestników zaplanuj na ostatnie 10-15 minut szkolenia.<br><br>
                <strong class="text-primary">Link do ankiety:</strong><br><small><a
                  :href="qrCodeValue"
                  target="_blank"
                >{{
                  qrCodeValue
                }}</a></small><br><br>
              </template>
              <strong class="text-primary">Ilość wypełnionych ankiet:</strong><br>
              <div class="font-large-1">
                {{ results.responses }}
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card v-if="results.responses">
          <h4 class="mb-2">
            Wyniki ankiety
          </h4><hr>
          <h5 class="mt-2">
            Ocena programu szkolenia i metod prowadzenia szkolenia
          </h5>
          <b-row>
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem
          dotychczasową oraz zdobyłem nowe umiejętności potrzebne na moim
          stanowisku pracy"
            >Dzięki udziałowi w szkoleniu zdobyłem nową wiedzę/utrwaliłem
              dotychczasową oraz zdobyłem nowe umiejętności potrzebne na moim
              stanowisku pracy
              <apexchart
                :series="getChartSeries(results.question1)"
                :options="getChartOptions({ type: 'agree' })"
              />
            </b-col>
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
            />
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Metody szkolenia, rytm pracy i środki dydaktyczne pomogły osiągnąć
          cele szkolenia"
            >Metody szkolenia, rytm pracy i środki dydaktyczne pomogły osiągnąć
              cele szkolenia
              <apexchart
                :series="getChartSeries(results.question2)"
                :options="getChartOptions({ type: 'agree' })"
              />
            </b-col>
          </b-row><hr>
          <h5 class="mt-2">
            Ocena pracy trenera
          </h5>
          <b-row>
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Doświadczenie trenera było adekwatne do tematu szkolenia"
            >Doświadczenie trenera było adekwatne do tematu szkolenia
              <apexchart
                :series="getChartSeries(results.question3)"
                :options="getChartOptions({ type: 'agree' })"
              />
            </b-col>
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Postawa trenera była przyjazna, profesjonalna i
          zaangażowana"
            >Postawa trenera była przyjazna, profesjonalna i
              zaangażowana
              <apexchart
                :series="getChartSeries(results.question4)"
                :options="getChartOptions({ type: 'agree' })"
              />
            </b-col>
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Sposób przekazywania wiedzy, prowadzenia dyskusji i
          odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu
          szkolenia"
            >Sposób przekazywania wiedzy, prowadzenia dyskusji i
              odpowiadania na pytania sprzyjał atmosferze i osiągnięciu celu
              szkolenia
              <apexchart
                :series="getChartSeries(results.question5)"
                :options="getChartOptions({ type: 'agree' })"
              />
            </b-col>
          </b-row><hr>
          <h5 class="mt-2">
            W jakim stopniu szkolenie, w którym uczestniczyłeś/aś będzie miało wpływ
            na następujące aspekty?
          </h5>
          <b-row>
            <!-- <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Jakość Twojej pracy"
            >Jakość Twojej pracy
              <apexchart
                :series="getChartSeries(results.question6)"
                :options="getChartOptions({ type: 'impact' })"
              />
            </b-col> -->
            <!-- <b-col
              class="chart-column"
              cols="12"
              lg="4"
            /> -->
            <b-col
              class="chart-column"
              cols="12"
              lg="4"
              title="Efektywność Twojej pracy - możliwość osiągnięcia lepszych
          efektów"
            >Poprawę efektywności Twojej pracy
              <apexchart
                :series="getChartSeries(results.question7)"
                :options="getChartOptions({ type: 'impact' })"
              />
            </b-col>
          </b-row><hr>
          <h5 class="mt-2">
            Referencje
          </h5>
          <b-row>
            <b-col
              cols="12"
              lg="4"
              class="chart-column"
              title="Czy poleciłbyś to szkolenie kolegom/koleżankom?"
            >
              Czy poleciłbyś to szkolenie kolegom/koleżankom?
              <apexchart
                :series="getChartSeries(results.question8)"
                :options="getChartOptions({ type: 'yesno' })"
              />
            </b-col>
          </b-row>
          <hr> <h5 class="mt-2">
            Opinie o szkoleniu
          </h5>
          <b-list-group>
            <b-list-group-item
              v-for="(comment, index) in filteredPollComments"
              :key="index"
            >
              {{ comment }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
      <div
        v-else
        class="text-center"
      >
        <b-spinner style="width: 4em; height: 4em" />
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup, BListGroupItem, BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BSpinner,
    apexchart: VueApexCharts,
    VueQrcode,
  },
  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      series: [44, 55, 13],
      results: {
        comments: [],
        question1: { AGREE: 0, PARTIALLY_AGREE: 0, DO_NOT_AGREE: 0 },
        question2: { AGREE: 0, PARTIALLY_AGREE: 0, DO_NOT_AGREE: 0 },
        question3: { AGREE: 0, PARTIALLY_AGREE: 0, DO_NOT_AGREE: 0 },
        question4: { AGREE: 0, PARTIALLY_AGREE: 0, DO_NOT_AGREE: 0 },
        question5: { AGREE: 0, PARTIALLY_AGREE: 0, DO_NOT_AGREE: 0 },
        question6: { BIG_IMPACT: 0, MODERATE_IMPACT: 0, SMALL_IMPACT: 0 },
        question7: { BIG_IMPACT: 0, MODERATE_IMPACT: 0, SMALL_IMPACT: 0 },
        question8: {
          DEFINITELY_YES: 1,
          YES: 1,
          NO: 0,
          DEFINITELY_NO: 0,
        },
        responses: null,
      },
    }
  },
  computed: {
    qrCodeValue() {
      return `${window.location.origin}/poll/${this.trainingCourseData.id}/${this.trainingCourseData.uuid}`
    },
    filteredPollComments() {
      return this.results.comments.filter(comment => comment !== null)
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.$store
        .dispatch('trainingCourse/fetchPollResults', {
          id: this.trainingCourseData.id,
        })
        .then(response => {
          if (response && response.status === 200) {
            this.results = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getChartSeries(question) {
      return [
        (question.AGREE || question.BIG_IMPACT || question.DEFINITELY_YES) || 0,
        (question.PARTIALLY_AGREE || question.MODERATE_IMPACT || question.YES) || 0,
        (question.DO_NOT_AGREE || question.SMALL_IMPACT || question.NO) || 0,
        (question.DEFINITELY_NO),
      ].filter(value => value !== undefined)
    },
    getChartOptions(question) {
      let labels = []
      let colors = ['#28c76f', '#ff9f43', '#ea5455']
      switch (question.type) {
        case 'agree':
          labels = ['Zgadzam się', 'Częściowo się zgadzam', 'Nie zgadzam się']
          break
        case 'impact':
          labels = ['Duży wpływ', 'Umiarkowany wpływ', 'Mały wpływ']
          break
        case 'yesno':
          labels = ['Zdecydowanie tak', 'Tak', 'Nie', 'Zdecydowanie nie']
          colors = ['#00a081', '#00e396', '#ff4560', '#F36363']
          break
        default:
          break
      }

      return {
        chart: {
          width: 380,
          type: 'pie',
          animations: { enabled: false },
        },
        colors,
        legend: {
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            dataLabels: { offset: -10 },
          },
        },
        labels,
        responsive: [{
          breakpoint: 1650,
          options: {
            chart: {
              width: 255,
            },
          },
        }],
        dataLabels: {
          formatter(value) {
            return `${Math.round(value)}%`
          },
        },
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";

.qrcode {
  width: 100% !important;
  height: auto !important;
  object-fit: contain;
  image-rendering: pixelated;
}

.chart-column {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  text-align: center;

  .apexcharts-canvas {
    margin: 0 auto;
  }
}
</style>
