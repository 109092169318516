<template>
  <div>
    <transition
      name="fade"
      mode="out-in"
    >
      <b-tabs
        v-if="trainingCourseData"
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
        lazy
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Informacje o szkoleniu</span>
          </template>
          <training-courses-tab-info
            :training-course-data="trainingCourseData"
            @refetch-data="fetchData"
          />
        </b-tab>
        <b-tab v-if="isAdmin || isManager || isTrainer">
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Uczestnicy</span>
          </template>
          <training-courses-tab-enrolments
            :training-course-data="trainingCourseData"
            @refetch-data="fetchData"
          />
        </b-tab>
        <b-tab v-if="!isEmployee || trainingCourseData.isCurrentUserEnrolled">
          <template #title>
            <feather-icon
              icon="FileTextIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Zadanie poszkoleniowe</span>
          </template>
          <training-courses-tab-actions
            :training-course-data.sync="trainingCourseData"
          />
        </b-tab>
        <b-tab v-if="isAdmin || isFordPoland || isTrainer">
          <template #title>
            <feather-icon
              icon="CheckSquareIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Ankieta poszkoleniowa</span>
          </template>
          <training-courses-tab-poll :training-course-data="trainingCourseData" />
        </b-tab>
        <b-tab v-if="(isAdmin || isManager) && !trainingCourseData.isFree">
          <template #title>
            <feather-icon
              icon="DollarSignIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Faktury</span>
          </template>
          <training-courses-tab-invoices
            :training-course-data="trainingCourseData"
          />
        </b-tab>
      </b-tabs>
      <div
        v-else
        class="text-center"
      >
        <b-spinner style="width: 4em; height: 4em" />
      </div>
    </transition>
  </div>
</template>

<script>
import { BTabs, BTab, BSpinner } from 'bootstrap-vue'
import TrainingCoursesTabEnrolments from './TrainingCoursesTabEnrolments.vue'
import TrainingCoursesTabInfo from './TrainingCoursesTabInfo.vue'
import TrainingCoursesTabInvoices from './TrainingCoursesTabInvoices.vue'
import TrainingCoursesTabActions from './TrainingCoursesTabActions.vue'
import TrainingCoursesTabPoll from './TrainingCoursesTabPoll.vue'

export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    TrainingCoursesTabEnrolments,
    TrainingCoursesTabInfo,
    TrainingCoursesTabInvoices,
    TrainingCoursesTabActions,
    TrainingCoursesTabPoll,
  },

  data() {
    return {
      loading: false,
      loadingEnrolments: false,
      trainingCourseData: null,
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.userData
    },
  },
  beforeMount() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$store
        .dispatch('trainingCourse/fetchTrainingCourse', {
          id: this.$router.currentRoute.params.id,
        })
        .then(response => {
          if (response && response.status === 200) {
            this.trainingCourseData = response.data
            this.$root.$emit('pageTitle:set', this.trainingCourseData.name)
          } else {
            this.$router.replace('/training-courses/list')
            let error = response && response.data['hydra:description'] ? response.data['hydra:description'] : null
            switch (error) {
              case 'Not Found':
                error = 'Nie znaleziono szkolenia.'
                break
              default:
                error = 'Serwer nie odpowiada.'
                break
            }
            this.$root.$emit(
              'global:error',
              `${error}`,
            )
          }
        })
    },

  },
}
</script>
