<template>
  <div>
    <b-card>

      <h4 class="mb-2">
        Faktury
      </h4>

      <b-table
        class="position-relative"
        :items="invoices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Brak faktur."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        tbody-tr-class="cursor-pointer"
        hover
        bordered
        @row-clicked="downloadInvoice"
      >
        <template
          v-if="!invoices || !invoices.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy faktur
          </div>
        </template>

        <template #cell(isPaid)="{item}">
          <b-badge
            class="btn-icon"
            :variant="item.isPaid ? 'success' : 'danger'"
          >
            {{ item.isPaid ? 'Opłacona': 'Nieopłacona' }}
          </b-badge>
        </template>

        <template #cell(actions)="{item}">
          <div style="white-space: nowrap">
            <b-button
              class="btn-icon"
              variant="light"
              title="Pobierz"
              size="sm"
              @click="downloadInvoice(item)"
            >
              Pobierz
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BSpinner, BBadge,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import { date } from '@/filters'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BSpinner,
    BBadge,
  },
  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isSortDirDesc: false,
      sortBy: 'lastName',
      tableColumns: [
        { key: 'number', label: 'Nr faktury' },
        { key: 'dealerName', label: 'Nazwa dealera' },
        { key: 'priceGross', label: 'Wartość brutto' },
        { key: 'paymentDate', label: 'Termin płatności', formatter: date },
        { key: 'isPaid', label: 'Status płatności' },
        { key: 'actions', label: 'Pobierz fakturę' },
      ],
    }
  },
  computed: {
    invoices() {
      const list = this.$store.state.trainingCourse.invoices[
        this.trainingCourseData.id
      ]
        ? this.$store.state.trainingCourse.invoices[
          this.trainingCourseData.id
        ]['hydra:member']
        : []
      return list
    },
  },
  beforeMount() {
    this.fetchInvoices().finally(() => {
      this.loading = false
    })
  },
  methods: {
    resolveDepartment,
    downloadInvoice(invoice) {
      window.open(invoice.downloadUrl)
    },

    fetchInvoices() {
      return this.$store
        .dispatch(
          'trainingCourse/fetchInvoicesForTrainingCourse',
          this.$router.currentRoute.params.id,
        ).then(() => {
          this.trainingCourseData.invoicesCount = this.invoices.length
        })
    },
  },
}
</script>

<style>
</style>
