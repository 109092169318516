<template>
  <div>
    <b-card>
      <h4 class="mb-2">
        {{ isTrainer ? "Wiadomość od Trenera" : "Materiały poszkoleniowe" }}
      </h4>
      <template v-if="isTrainer">
        <quill-editor
          v-if="isTrainer"
          v-model="messageContent"
          :options="quillOptions"
        />
        <hr>
      </template>
      <training-courses-files
        v-if="trainingCourseData.afterTrainingMaterials.length || isTrainer"
        v-model="afterTrainingMaterialsFiles"
        :existing-files="trainingCourseData.afterTrainingMaterials"
        :files-to-delete.sync="afterTrainingMaterialsToDelete"
        :upload="isTrainer"
      />
      <div v-else>
        Brak materiałów poszkoleniowych do pobrania.
      </div>
      <template v-if="isTrainer">
        <hr>
        <b-button
          variant="primary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          :disabled="loading || !!uploading"
          @click="sendTrainingCourseMessage"
        >
          Wyślij wiadomość i pliki {{ afterTrainingMaterialsToDelete.length ? ' oraz usuń zaznaczone' : null }}
        </b-button>
      </template>
    </b-card>
    <b-card>
      <h4 class="mb-2">
        Obecność i zadania
      </h4>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="enrolmentsWithUsersAndFiles"
        responsive
        :fields="visibleTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Brak zapisanych uczestników."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        bordered
      >
        <template #cell(attendance)="{ item }">
          <b-button
            block
            class="btn-icon"
            :variant="(item.attendance ? 'success' : 'outline-danger')"
            :disabled="!isTrainer"
            @click="
              item.attendance = !item.attendance;
              setAttendance(item);
            "
          >
            <feather-icon
              :icon="item.attendance ? 'CheckIcon' : 'XIcon'"
            /></b-button>
        </template>
        <template #cell(comment)="{ item }">
          <!-- <form @submit.prevent="setAttendance(item)"> -->
          <b-form-input
            v-model="item.comment"
            v-autowidth="{maxWidth: '40em', minWidth: 'calc(100% - 30px)'}"
            :disabled="!isTrainer"
            style="height: auto;"
            @change="setAttendance(item)"
          />
          <!-- </form> -->
        </template>
        <template #cell(files)="{ item }">
          <training-courses-files
            v-if="!(isEnrolledManager && item.user['@id'] !== currentUser['@id'])"
            v-model="userFiles"
            small
            :existing-files="item.files"
            :files-to-delete.sync="userFilesToDelete"
            :upload="(isEmployee || isEnrolledManager) && currentUser['@id'] == item.user['@id']"
            style="margin-bottom: -11px"
            @input="uploadUserFiles"
            @delete="deleteUserFile"
          />
        </template>
        <template
          v-if="!enrolments || !enrolments.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy uczestników
          </div>
        </template>
      </b-table>

      <b-button
        v-if="['ROLE_ADMIN', 'ROLE_TRAINER', 'ROLE_FORD_POLAND'].some(r => roles.includes(r))"
        variant="gradient-primary"
        @click="exportXls()"
      >
        <span class="text-nowrap">Eksport xls</span>
      </b-button>
    </b-card>

    <b-modal
      ref="uploadingModal"
      hide-header
      hide-footer
      :visible="!!uploading"
      no-close-on-esc
      no-close-on-backdrop
    >
      <h4>Przesyłanie plików...</h4>
      <b-progress
        animated
        :value="uploadProgress"
      />
    </b-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import {
  BCard,
  BTable,
  BSpinner,
  BButton,
  BModal,
  BProgress,
  BFormInput,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import visibleTableColumns from '@/helpers/visibleTableColumns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TrainingCoursesFiles from './TrainingCoursesFiles.vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css'

export default {
  components: {
    BCard,
    BTable,
    BSpinner,
    BButton,
    BModal,
    BProgress,
    BFormInput,

    TrainingCoursesFiles,
    quillEditor,
  },
  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isSortDirDesc: false,
      sortBy: 'lastName',
      tableColumns: [
        { key: 'user.lastName', sortable: true, label: 'Nazwisko' },
        { key: 'user.firstName', label: 'Imię' },
        {
          key: 'user.email',
          label: 'Email',
          visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER', 'ROLE_MANAGER'],
        },
        {
          key: 'user.dealer.name',
          label: 'Dealer',
          visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER', 'ROLE_MANAGER'],
        },
        {
          key: 'attendance',
          label: 'Obecność',
          visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER', 'ROLE_MANAGER'],
        },
        {
          key: 'comment',
          label: 'Komentarz',
          visibleTo: ['ROLE_ADMIN', 'ROLE_FORD_POLAND', 'ROLE_TRAINER', 'ROLE_MANAGER'],
        },
        { key: 'files', label: 'Zadanie' },
      ],

      afterTrainingMaterialsFiles: [],
      afterTrainingMaterialsToDelete: [],
      userFiles: [],
      userFilesToDelete: [],
      userFilesList: [],
      uploading: 0,
      uploadingTotal: 0,

      messageContent: '',

      quillOptions: {
        placeholder: 'Wprowadź wiadomość dla Uczestników',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ align: [] }],

            ['clean'], // remove formatting button
          ],
        },
      },
    }
  },
  computed: {
    enrolments() {
      const list = this.$store.state.trainingCourse.enrolments[
        this.trainingCourseData.id
      ]
        ? this.$store.state.trainingCourse.enrolments[
          this.trainingCourseData.id
        ]['hydra:member']
        : []
      return list
    },

    enrolmentsWithUsersAndFiles() {
      const list = []
      this.enrolments.forEach(enrolment => {
        list.push({
          ...enrolment,
          user: this.findUser(enrolment.user),
          files: this.findUserFiles(enrolment.user),
        })
      })
      return list
    },

    users() {
      return this.$store.state.user.list
        ? this.$store.state.user.list['hydra:member']
        : []
    },

    files() {
      const list = this.$store.state.trainingCourse.files[
        this.trainingCourseData.id
      ]
        ? this.$store.state.trainingCourse.files[this.trainingCourseData.id][
          'hydra:member'
        ]
        : []
      return list
    },

    uploadProgress() {
      return (Math.abs(this.uploading - this.uploadingTotal) / this.uploadingTotal) * 100
    },

    isEnrolledManager() {
      return this.isManager && this.enrolmentsWithUsersAndFiles.findIndex(enrolment => enrolment.user && enrolment.user['@id'] === this.currentUser['@id']) !== -1
    },

    visibleTableColumns,

    userData() {
      return this.$store.state.auth.userData ? this.$store.state.auth.userData : {}
    },

    roles() {
      return this.userData && this.userData.roles ? this.userData.roles : []
    },
  },
  beforeMount() {
    Promise.all([
      this.fetchEnrolments(),
      this.fetchUsers(),
      this.fetchFiles(),
    ]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    resolveDepartment,

    findUser(userId) {
      return this.users.find(user => user['@id'] === userId)
    },

    findUserFiles(userId) {
      return this.files.filter(file => file.owner === userId)
    },

    fetchEnrolments() {
      return this.$store
        .dispatch(
          'trainingCourse/fetchEnrolmentsForTrainingCourse',
          this.$router.currentRoute.params.id,
        )
        .then(() => {
          // this.trainingCourseData.enrolmentsCount = this.enrolments.length
        })
    },

    fetchUsers() {
      return this.$store.dispatch('user/fetchList')
    },

    fetchFiles() {
      return this.$store.dispatch(
        'trainingCourse/fetchFilesForTrainingCourse',
        this.$router.currentRoute.params.id,
      )
    },

    uploadFiles(files, destination, updateUserFiles) {
      this.uploading = files.length
      this.uploadingTotal = files.length

      const uploadNext = file => {
        const formdata = new FormData()
        formdata.append('file', file)
        return new Promise((resolve, reject) => {
          this.$http
            .post('media_objects', formdata)
            .then(fileResponse => {
              if (fileResponse && fileResponse.status === 201) {
                if (updateUserFiles) {
                  this.$http
                    .put(
                      `training_courses/${this.trainingCourseData.id}/files`,
                      {
                        mediaObject: fileResponse.data['@id'],
                      },
                    )
                    .then(response => {
                      resolve(response)
                    })
                    .catch(error => {
                      reject(error)
                    })
                } else {
                  resolve(fileResponse)
                }
              } else {
                reject(fileResponse)
              }
            })
            .catch(error => resolve(error))
        })
      }

      const uploadStack = () => {
        const stack = []
        files.forEach(file => {
          stack.push(
            uploadNext(file)
              .then(response => {
                this.uploading -= 1
                files.splice(files.indexOf(file), 1)
                if (destination) destination.push(response.data['@id'])
              })
              .catch(error => {
                this.uploading = 0
                this.$root.$emit(
                  'global:error',
                  `Nie udało się załadować pliku ${file.name}. ${
                    error
                      ? error.data['hydra:description']
                      : 'Serwer nie odpowiada.'
                  }`,
                )
              }),
          )
        })
        return Promise.all(stack)
      }

      return new Promise(resolve => {
        if (!files.length) {
          resolve()
        } else {
          uploadStack().finally(() => resolve())
        }
      })
    },

    deleteFiles(files, destination) {
      files.forEach(fileToDelete => {
        destination.splice(destination.indexOf(fileToDelete), 1)
      })
      files.splice(0)
    },

    sendTrainingCourseMessage() {
      this.loading = true
      this.deleteFiles(
        this.afterTrainingMaterialsToDelete,
        this.trainingCourseData.afterTrainingMaterials,
      )
      this.uploadFiles(
        this.afterTrainingMaterialsFiles,
        this.trainingCourseData.afterTrainingMaterials,
      ).then(() => {
        Promise.all([
          this.$store
            .dispatch('trainingCourse/editTrainingCourse', {
              id: this.trainingCourseData.id,
              payload: {
                afterTrainingMaterials:
                  this.trainingCourseData.afterTrainingMaterials.map(file => (file['@id'] ? file['@id'] : file)),
              },
            })
            .then(editResponse => {
              if (editResponse.status === 200) {
                this.$store
                  .dispatch('trainingCourse/fetchTrainingCourse', {
                    id: this.trainingCourseData.id,
                  })
                  .then(fetchResponse => {
                    if (fetchResponse.status === 200) {
                      this.$emit(
                        'update:trainingCourseData',
                        fetchResponse.data,
                      )
                      this.afterTrainingMaterialsFiles = []
                    } else {
                      this.$root.$emit(
                        'global:error',
                        `Wystąpił problem w trakcie odświeżania szkolenia. ${
                          fetchResponse
                            ? fetchResponse.data['hydra:description']
                            : 'Serwer nie odpowiada.'
                        }`,
                      )
                    }
                  })
              } else {
                this.$root.$emit(
                  'global:error',
                  `Wystąpił problem w trakcie zapisywania plików. ${
                    editResponse
                      ? editResponse.data['hydra:description']
                      : 'Serwer nie odpowiada.'
                  }`,
                )
              }
            }),

          this.messageContent
            ? this.$store
              .dispatch('trainingCourse/sendTrainingCourseMessage', {
                id: this.trainingCourseData['@id'],
                message: this.messageContent,
              })
              .then(response => {
                if (response.status === 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Wysłano wiadomość dla Uczestników.',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title:
                          'Nie udało się wysłać wiadomości dla Uczestników.',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: response
                        ? response.data['hydra:description']
                        : null,
                    },
                  })
                }
              })
            : null,
        ]).finally(() => {
          this.loading = false
        })
      })
    },

    setAttendance(enrolment) {
      this.$store.dispatch('trainingCourse/setAttendance', {
        id: enrolment.id,
        trainingCourseId: this.trainingCourseData.id,
        attendance: enrolment.attendance,
        comment: enrolment.comment,
      })
    },

    uploadUserFiles() {
      this.uploadFiles(this.userFiles, this.userFilesList, true).then(() => {
        this.fetchFiles()
      })
    },

    deleteUserFile(fileToDelete) {
      this.$http.delete(fileToDelete['@id']).catch(error => this.$root.$emit(
        'global:error',
        `Wystąpił problem w trakcie usuwania pliku. ${
          error
            ? error.data['hydra:description']
            : 'Serwer nie odpowiada.'
        }`,
      )).then(response => {
        if (response && response.status === 204) {
          this.files.splice(this.files.findIndex(file => file['@id'] === fileToDelete['@id']), 1)
        }
      })
    },

    async exportXls() {
      const params = {
        trainingCourse: this.trainingCourseData['@id'],
      }
      const response = await this.$http.get('/training_courses/enrolments/export', { params, responseType: 'blob' })
      if (response.status === 200) {
        const blob = new Blob([response.data, 'application/vnd.ms-excel'])
        this.downloadBlob(blob, `${this.trainingCourseData.name}.xls`)
      }
    },
    downloadBlob(blob, name = 'file.txt') {
      if (
        window.navigator
      && window.navigator.msSaveOrOpenBlob
      ) return window.navigator.msSaveOrOpenBlob(blob)

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = data
      link.download = name

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )

      setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        link.remove()
      }, 100)
      return 1
    },
  },
}
</script>

<style>
</style>
