<template>
  <div>
    <training-courses-tab-enrolments-add-new
      v-if="isManager"
      :is-add-new-user-sidebar-active.sync="isAddNewEnrolmentSidebarActive"
      :training-course-data="trainingCourseData"
      :enrolments-data="enrolments"
      @refetch-data="fetchEnrolments(); $emit('refetch-data')"
    />

    <b-card>
      <div class="d-flex mb-2 justify-content-between align-items-center">
        <h4 class="mb-0">
          Lista uczestników
        </h4>
        <div class="d-flex align-items-center">
          <!-- <div
            v-if="!loading"
            class="font-large-1"
          >
            <strong :class="maxReached ? 'text-secondary' : 'text-primary'">{{
              enrolments.length
            }}</strong>&nbsp;/&nbsp;{{ trainingCourseData.numberOfParticipants }}
          </div>
          <div
            v-else
            class="font-large-1"
          >
            &bull; &bull; &bull;
          </div> -->
          <b-button
            v-if="isManager && trainingCourseIsUpcoming(trainingCourseData)"
            variant="gradient-secondary"
            class="ml-2 d-none d-sm-block"
            :disabled="maxReached"
            @click="isAddNewEnrolmentSidebarActive = true"
          >
            Zapisz na szkolenie
          </b-button>
        </div>
      </div>
      <b-button
        v-if="isManager && trainingCourseIsUpcoming(trainingCourseData)"
        variant="gradient-secondary"
        class="d-sm-none"
        block
        style="margin: -16px 0 14px"
        :disabled="maxReached"
      >
        Zapisz na szkolenie
      </b-button>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="enrolmentsWithUsers"
        responsive
        :fields="visibleTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Brak zapisanych uczestników."
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
        bordered
      >
        <template
          v-if="!enrolments || !enrolments.length"
          #table-busy
        >
          <div class="text-center">
            <b-spinner class="align-middle mr-1" /> Pobieranie listy uczestników
          </div>
        </template>

        <template #cell(actions)="data">
          <div style="white-space: nowrap">
            <b-button
              size="sm"
              variant="light"
              @click="enrolmentToDelete = data.item"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Usuń</span>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal
      v-if="enrolmentToDelete"
      v-model="deleteModalOpen"
      centered
      modal-class="modal-danger"
      title="Potwierdź usunięcie"
      ok-variant="danger"
      cancel-variant="light"
      ok-title="Usuń"
      cancel-title="Anuluj"
      @ok="deleteEnrolment"
    >
      Na pewno wypisać pracownika
      <strong>{{ enrolmentToDelete.user.firstName }} {{ enrolmentToDelete.user.lastName }} </strong> ze szkolenia?</b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BTable, BSpinner,
} from 'bootstrap-vue'
import resolveDepartment from '@/helpers/resolveDepartment'
import visibleTableColumns from '@/helpers/visibleTableColumns'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import trainingCourseIsUpcoming from '@/helpers/trainingCourseIsUpcoming'
import TrainingCoursesTabEnrolmentsAddNew from './TrainingCoursesTabEnrolmentsAddNew.vue'

export default {
  components: {
    BCard,
    BButton,
    BTable,
    BSpinner,
    TrainingCoursesTabEnrolmentsAddNew,
  },
  props: {
    trainingCourseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isSortDirDesc: false,
      sortBy: 'lastName',
      tableColumns: [
        { key: 'user.lastName', sortable: true, label: 'Nazwisko' },
        { key: 'user.firstName', label: 'Imię' },
        { key: 'user.email', label: 'Email' },
        { key: 'user.dealer.name', label: 'Dealer', visibleTo: ['ROLE_ADMIN'] },
        { key: 'actions', label: 'Akcje', visibleTo: ['ROLE_ADMIN'] },
      ],
      enrolmentToDelete: null,
      isAddNewEnrolmentSidebarActive: false,
    }
  },
  computed: {
    maxReached() {
      return (
        // this.enrolments.length >= this.trainingCourseData.numberOfParticipants
        this.trainingCourseData.enrolmentsCount >= this.trainingCourseData.numberOfParticipants
      )
    },
    enrolments() {
      const list = this.$store.state.trainingCourse.enrolments[
        this.trainingCourseData.id
      ]
        ? this.$store.state.trainingCourse.enrolments[
          this.trainingCourseData.id
        ]['hydra:member']
        : []
      return list
    },

    enrolmentsWithUsers() {
      const list = []
      this.enrolments.forEach(enrolment => {
        list.push({ ...enrolment, user: this.findUser(enrolment.user) })
      })
      return list
    },

    users() {
      return this.$store.state.user.list ? this.$store.state.user.list['hydra:member'] : []
    },

    deleteModalOpen: {
      get() {
        return !!this.enrolmentToDelete
      },
      set(value) {
        this.enrolmentToDelete = value
      },
    },

    visibleTableColumns,
  },
  beforeMount() {
    Promise.all([this.fetchEnrolments(), this.fetchUsers()]).finally(() => {
      this.loading = false
    })
  },
  methods: {
    trainingCourseIsUpcoming,
    resolveDepartment,
    // editUserRedirect(user) {
    //   this.$router.push(`/users/edit/${user.id}`)
    // },

    findUser(userId) {
      return this.users.find(user => user['@id'] === userId)
    },

    fetchEnrolments() {
      return this.$store
        .dispatch(
          'trainingCourse/fetchEnrolmentsForTrainingCourse',
          this.$router.currentRoute.params.id,
        ).then(() => {
          // this.trainingCourseData.enrolmentsCount = this.enrolments.length
        })
    },

    fetchUsers() {
      return this.$store.dispatch('user/fetchList')
    },
    deleteEnrolment() {
      this.$store.dispatch('trainingCourse/deleteEnrolment', { id: this.enrolmentToDelete.id }).then(response => {
        if (response && response.status === 204) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pracownik został wypisany',
              icon: 'UserXIcon',
              variant: 'success',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Nie udało się wypisać pracownika.',
              text: response ? response.data['hydra:description'] : null,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
            timeout: false,
          })
        }
        this.$store.dispatch('user/fetchList')
        this.$emit('refetch-data')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nie udało się wypisać pracownika.',
            text: error ? error.data['hydra:description'] : null,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
          timeout: false,
        })
      }).finally(() => {
        this.fetchEnrolments()
      })
    },
  },
}
</script>

<style>
</style>
